import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Player from './sub/Player';
import './Scoresheet.css';
import Logo from '../../assets/logo.svg';

class Scoresheet extends Component {
  constructor(props) {
    super(props);
    const { nextInLineId, players } = this.props;
    this.scoreTable = players.map((player, i) => ({ id: i, totalBoxes: 0 }));
    this.state = {
      leaderGrandTotal: 0,
      leaderId: false,
      nextInLineId,
      gameStarted: false,
      gameOver: false,
    };
  }

  doUpdatePlayer = (id, name, grandTotal, grandTotalLeft, totalBoxes) => {
    const { nextInLineId } = this.props;
    let leaderId = false;
    let leaderGrandTotal = 0;
    let isGameOver = true;
    this.scoreTable[id] = {
      id,
      grandTotal,
      grandTotalLeft,
      totalBoxes,
    };
    this.scoreTable.forEach((player) => {
      if (player.grandTotalLeft) isGameOver = false;
      if (player.grandTotal > leaderGrandTotal) {
        leaderId = player.id;
        leaderGrandTotal = player.grandTotal;
      } else if (player.grandTotal === leaderGrandTotal) leaderId = false;
    });

    let nextSorted = [...this.scoreTable];
    nextSorted = nextSorted.concat(nextSorted.splice(0, nextInLineId));
    const next = [...nextSorted].sort((a, b) => a.totalBoxes - b.totalBoxes).shift();

    this.setState({
      leaderId,
      leaderGrandTotal,
      nextInLineId: next.id,
      gameOver: isGameOver,
    }, () => {
      const { gameStarted, gameOver } = this.state;
      const { onGameStart, onGameOver } = this.props;
      if (!gameStarted) this.setState({ gameStarted: true }, onGameStart);
      else if (gameOver) onGameOver();
    });
  }

  renderPlayers = () => {
    const {
      leaderId,
      nextInLineId,
      gameOver,
      leaderGrandTotal,
    } = this.state;
    const { players, onSelectPlayer, config } = this.props;
    return players.map((player, i) => (
      <Player
        key={i}
        id={i}
        name={player}
        leaderId={leaderId}
        nextInLineId={nextInLineId}
        gameOver={gameOver}
        leaderGrandTotal={leaderGrandTotal}
        onUpdate={this.doUpdatePlayer}
        onSelectPlayer={onSelectPlayer}
        config={config}
      />
    ));
  }

  render() {
    const { config } = this.props;
    const totalBoxes = config.upperSection.boxes.length + config.lowerSection.boxes.length + 5;
    const boxHeight = `${100 / totalBoxes}%`;
    return (
      <div className='Scoresheet'>
        <div className='Scoresheet-column'>
          <div className='InfoBox InfoBox-header'>
            <img src={Logo} alt='Yazo' />
            <p>{config.type === 'maxo' ? 'MAXO Edition' : 'since 2018'}</p>
          </div>
          <div className='Scoresheet-main'>
            {config.upperSection.boxes.map((box, i) => <div key={i} style={{ height: boxHeight }} className='InfoBox'>{box.name}</div>)}
            <div style={{ height: boxHeight }} className='InfoBox InfoBox-sum'>Sub Total</div>
            <div style={{ height: boxHeight }} className='InfoBox InfoBox-sum'>BONUS</div>
            {config.lowerSection.boxes.map((box, i) => <div key={i} style={{ height: boxHeight }} className='InfoBox'>{box.name}</div>)}
            <div style={{ height: boxHeight }} className='InfoBox InfoBox-sum'>Sub Total</div>
            <div style={{ height: boxHeight, backgroundColor: 'rgba(0, 0, 0, .1)', borderBottom: '1px solid rgba(0, 0, 0, .3)' }} className='InfoBox InfoBox-sum'>Grand Total</div>
            <div style={{ height: boxHeight, borderBottom: 0, background: 'none' }} className='InfoBox InfoBox-sum'>Potential Max</div>
          </div>
        </div>
        {this.renderPlayers()}
      </div>
    );
  }
}

const boxes = PropTypes.shape({
  name: PropTypes.string.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
});

Scoresheet.propTypes = {
  players: PropTypes.arrayOf(PropTypes.string).isRequired,
  onGameStart: PropTypes.func.isRequired,
  onGameOver: PropTypes.func.isRequired,
  onSelectPlayer: PropTypes.func.isRequired,
  nextInLineId: PropTypes.number.isRequired,
  config: PropTypes.shape({
    type: PropTypes.string.isRequired,
    throwCount: PropTypes.bool.isRequired,
    upperSection: PropTypes.shape({
      bonusAt: PropTypes.number.isRequired,
      boxes: PropTypes.arrayOf(boxes),
    }).isRequired,
    lowerSection: PropTypes.shape({
      boxes: PropTypes.arrayOf(boxes),
    }).isRequired,
  }).isRequired,
};

export default Scoresheet;
