import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Modal.css';

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = { activated: true };
  }

  componentDidMount = () => {
    window.addEventListener('keydown', this.doKeyDown);
    window.addEventListener('submit', this.doClose);
  }

  componentWillUnmount = () => {
    window.removeEventListener('keydown', this.doKeyDown);
    window.removeEventListener('submit', this.doClose);
  }

  doClose = () => {
    const { onClose } = this.props;
    this.setState({ activated: false }, () => onClose());
  }

  renderTitle = () => {
    const { title } = this.props;
    return title ? <h1>{title}</h1> : null;
  }

  renderModal = () => {
    const { children } = this.props;
    return (
      <div className='Modal'>
        <div className="Modal-overlay" onClick={this.doClose} />
        <div className="Modal-window">
          <div className="Modal-exit" onClick={this.doClose} />
          {this.renderTitle()}
          <div className="Modal-area">
            {children}
          </div>
        </div>
      </div>
    );
  }

  doKeyDown = (e) => {
    if (e.keyCode === 27) this.doClose();
  }

  render() {
    const { activated } = this.state;
    return activated ? this.renderModal() : null;
  }
}

Modal.defaultProps = {
  onClose: () => {},
};

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

export default Modal;
