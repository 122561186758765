import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';
import ActionButton from './ActionButton';
import './PlayerEditor.css';

class PlayerEditor extends Component {
  constructor(props) {
    super(props);
    const { name } = this.props;
    this.state = {
      newName: name,
      remove: false,
    };
  }

  doChangeName = (e) => {
    if (e.target.value.length < 7) this.setState({ newName: e.target.value });
  }

  doSubmit = (e) => {
    e.preventDefault();
    const { newName, remove } = this.state;
    const {
      id,
      onRemovePlayer,
      onUpdatePlayer,
      onNewPlayer,
    } = this.props;
    if (newName) {
      if (id !== false) {
        if (remove) onRemovePlayer(id);
        else onUpdatePlayer(id, newName);
      } else onNewPlayer(newName);
    }
  }

  renderRemovePlayer = () => {
    const { id } = this.props;
    if (id !== false) {
      return (
        <div>
          <hr />
          <ActionButton
            type='red'
            text='Wipe player from sheet'
            onClick={() => this.setState({ remove: true })}
          />
        </div>
      );
    }
    return null;
  }

  getTitle = () => {
    const { name } = this.props;
    return name ? 'Edit player' : 'Add new player';
  }

  renderSaveButton = () => {
    const { name } = this.props;
    const { newName } = this.state;
    return (
      <ActionButton
        text={name ? 'Update' : 'Add'}
        type={name ? 'green' : 'blue'}
        disabled={(!newName)}
      />
    );
  }

  render() {
    const { onClose, gameStarted } = this.props;
    const { newName } = this.state;
    return (
      <Modal title={this.getTitle()} onClose={onClose}>
        <div className='PlayerEditor'>
          <p>Please, enter name:</p>
          <form onSubmit={this.doSubmit}>
            <input autoFocus type="text" value={newName} onChange={this.doChangeName} />
            {this.renderSaveButton()}
            {this.renderRemovePlayer()}
          </form>
          <hr />
          {gameStarted && (
            <p className="caution">
              <h2>A word of caution.</h2>
              When a player is added or wiped,
              <br />
              current game will be reset.
            </p>
          )}
        </div>
      </Modal>
    );
  }
}

PlayerEditor.defaultProps = {
  id: false,
  name: '',
  gameStarted: false,
};

PlayerEditor.propTypes = {
  id: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  name: PropTypes.string,
  gameStarted: PropTypes.bool,
  onNewPlayer: PropTypes.func.isRequired,
  onUpdatePlayer: PropTypes.func.isRequired,
  onRemovePlayer: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PlayerEditor;
