import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ScoreBox from './ScoreBox';
import SumBox from './SumBox';

class Section extends Component {
  constructor(props) {
    super(props);
    const { max } = this.props;
    this.state = {
      total: 0,
      bonusBalance: 0,
      totalLeft: max,
      boxes: 0,
      throws: 0,
    };
  }

  doUpdate = (
    value,
    initValue,
    wastedValue,
    throws,
    initThrows,
    bonusBalance,
    initBonusBalance,
    count,
  ) => {
    this.setState((prevState) => ({
      total: (prevState.total - initValue) + value,
      totalLeft: prevState.totalLeft - wastedValue,
      bonusBalance: (prevState.bonusBalance - initBonusBalance) + bonusBalance,
      boxes: prevState.boxes + count,
      throws: (prevState.throws - initThrows) + throws,
    }), this.onChange);
  }

  onChange = () => {
    const {
      total,
      totalLeft,
      bonusBalance,
      boxes,
      throws,
    } = this.state;
    const { onChange } = this.props;
    onChange(total, totalLeft, bonusBalance, boxes, throws);
  }

  render() {
    const { total } = this.state;
    const {
      name,
      boxes,
      boxHeight,
      bonusStep,
      savedThrows,
      enableThrowCount,
    } = this.props;
    return (
      <div style={{ height: `calc(${boxHeight} * (${boxes.length + 1})` }}>
        {boxes.map((box, i) => (
          <ScoreBox
            title={`${name} |  ${box.name}`}
            key={i}
            min={box.min}
            max={box.max}
            step={box.step}
            onUpdate={this.doUpdate}
            style={{ height: `${(100 / (boxes.length + 1))}%` }}
            bonusStep={bonusStep}
            savedThrows={savedThrows}
            enableThrowCount={enableThrowCount}
          />
        ))}
        <SumBox value={total} style={{ height: `${(100 / (boxes.length + 1))}%` }} />
      </div>
    );
  }
}

const boxes = PropTypes.shape({
  name: PropTypes.string.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
});

Section.defaultProps = { bonusStep: 1 };

Section.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  max: PropTypes.number.isRequired,
  boxes: PropTypes.arrayOf(boxes).isRequired,
  boxHeight: PropTypes.string.isRequired,
  bonusStep: PropTypes.number,
  savedThrows: PropTypes.number.isRequired,
  enableThrowCount: PropTypes.bool.isRequired,
};

export default Section;
