import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './ActionButton.css';

class ActionButton extends Component {
  renderClassNames = () => {
    const { disabled, type, className } = this.props;
    const classNames = ['ActionButton'];
    if (disabled) classNames.push('ActionButton-disabled');
    if (className) classNames.push(className);
    if (type === 'red') {
      classNames.push('ActionButton-red');
    } else if (type === 'green') {
      classNames.push('ActionButton-green');
    } else {
      classNames.push('ActionButton-blue');
    }
    return classNames.join(' ');
  }

  render() {
    const { value, onClick, text } = this.props;
    return (
      <button
        className={this.renderClassNames()}
        type='submit'
        value={value}
        onClick={onClick}
      >
        {text}
      </button>
    );
  }
}

ActionButton.defaultProps = {
  disabled: false,
  type: '',
  value: 0,
  onClick: () => {},
  className: '',
};

ActionButton.propTypes = {
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.number,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default ActionButton;
