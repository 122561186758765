import React, { Component } from 'react';
import ReactGA from 'react-ga';
import CacheBuster from './CacheBuster';
import Scoresheet from './scoresheet/Scoresheet';
import Modal from './Modal';
import PlayerEditor from './PlayerEditor';
import MenuOption from './MenuOption';
import ActionButton from './ActionButton';
import './App.css';
import yatzoConfig from '../config/yatzo';
import maxoConfig from '../config/maxo';
import packageJson from '../../package.json';
import Logo from '../assets/logo.svg';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      players: [],
      gameIndex: 0,
      gameStarted: false,
      gameOver: false,
      gameFull: false,
      showInformation: false,
      showResetConfirm: false,
      showSwitchConfirm: false,
      selectedPlayer: false,
      showAddNewPlayer: false,
      config: yatzoConfig,
    };
  }

  track = (action) => {
    ReactGA.event({ category: 'Game', label: 'Game Events', action });
  }

  pageView = (page) => {
    ReactGA.ga('send', 'pageview', `/${page}`);
  }

  getOppositeConfig = () => {
    const { config } = this.state;
    return config.type !== 'maxo' ? maxoConfig : yatzoConfig;
  }

  doGameStart = () => {
    const { config } = this.state;
    const gameType = config.type.charAt(0).toUpperCase() + config.type.slice(1);
    this.setState({ gameStarted: true });
    this.track(`${gameType} started`);
  }

  doGameOver = () => {
    this.setState({ gameOver: true });
    this.track('Finished');
  }

  doGameReset = () => {
    const { players } = this.state;
    this.setState((prevState) => ({
      gameIndex: (
        (players.length - (prevState.gameIndex % players.length)) + prevState.gameIndex
      ) || 0,
      gameStarted: false,
      gameOver: false,
      showResetConfirm: false,
      showSwitchConfirm: false,
    }));
  }

  doPlayAgain = () => {
    this.setState((prevState) => ({
      gameIndex: (prevState.gameIndex + 1),
      gameStarted: false,
      gameOver: false,
      showResetConfirm: false,
    }));
  }

  doShowAddNewPlayer = () => {
    const { gameFull } = this.state;
    if (!gameFull) this.setState({ showAddNewPlayer: true });
  }

  doAddNewPlayer = (name) => {
    this.setState((prevState) => ({
      players: [...prevState.players, name],
      gameFull: (prevState.players.length >= 5),
    }), this.doGameReset);
    this.track('Add player');
  }

  doUpdatePlayer = (id, name) => {
    const { players } = this.state;
    const updatedPlayerList = players;
    updatedPlayerList[id] = name;
    this.setState({ players: updatedPlayerList });
    this.track('Update player');
  }

  doRemovePlayer = (id) => {
    this.setState((prevState) => ({
      players: prevState.players.filter((p, i) => i !== (id)),
    }), () => {
      const { players } = this.state;
      this.setState({ gameFull: (players.length >= 6) });
      this.doGameReset();
      this.track('Wipe player');
    });
  }

  renderPlayerEditor = () => {
    const { selectedPlayer, showAddNewPlayer, gameStarted } = this.state;
    if (selectedPlayer || showAddNewPlayer) {
      if (selectedPlayer) this.pageView('edit-player');
      else this.pageView('new-player');
      return (
        <PlayerEditor
          id={selectedPlayer.id}
          name={selectedPlayer.name}
          gameStarted={gameStarted}
          onNewPlayer={this.doAddNewPlayer}
          onUpdatePlayer={this.doUpdatePlayer}
          onRemovePlayer={this.doRemovePlayer}
          onClose={() => this.setState({ selectedPlayer: false, showAddNewPlayer: false })}
        />
      );
    }
    return null;
  }

  renderGameReset = () => {
    const { showResetConfirm, gameOver } = this.state;
    if (showResetConfirm) {
      this.pageView('reset-confirm');
      return (
        <Modal title='RESET' onClose={() => this.setState({ showResetConfirm: false })}>
          <p>Reset scoresheet, are you sure?</p>
          <ActionButton
            type={gameOver ? 'green' : 'red'}
            text={gameOver ? "Yep, let's play again" : 'Yep, wipe the sheet'}
            onClick={() => {
              if (gameOver) this.doPlayAgain();
              else this.doGameReset();
            }}
          />
        </Modal>
      );
    }
    return null;
  }

  renderGameSwitch = () => {
    const { showSwitchConfirm, gameStarted } = this.state;
    if (showSwitchConfirm) {
      this.pageView('switch-confirm');
      return (
        <Modal title='Change game mode' onClose={() => this.setState({ showSwitchConfirm: false })}>
          <p>
            You&apos;re about to change game mode to
            {this.getOppositeConfig().type === 'maxo' ? ' 6-dice maxo edition' : ' 5-dice original yatzo'}
            , are you sure?
          </p>
          <ActionButton
            type='blue'
            text={`Yep, let's play ${this.getOppositeConfig().type}!`}
            onClick={() => {
              this.setState({ config: this.getOppositeConfig() }, this.doGameReset);
            }}
          />
          {gameStarted && (
            <p className="caution">
              <h2>A word of caution.</h2>
              When game mode changes,
              <br />
              current game will be reset.
            </p>
          )}
        </Modal>
      );
    }
    return null;
  }

  renderInformation = () => {
    const { showInformation } = this.state;
    if (showInformation) {
      this.pageView('info');
      return (
        <Modal title='Yatzo Yatzy' onClose={() => this.setState({ showInformation: false })}>
          <img src={Logo} alt='Yatzo' style={{ width: '40%', marginBottom: '20px' }} />
          <p>
            brought to you by the
            <br />
            Happy Yatzy Generation Association
            <br />
            (hereafter referred to as &quot;HYGA&quot;)
          </p>
          <h2>Privacy policy</h2>
          <p>
            HYGA does not collect personal data and the service is completely
            {' '}
            free to use. However, to help us make things better we use Google
            {' '}
            Analytic to track certin events in the application.
          </p>
          <p>
            But fear not, in order to reduce the impact we disabled functions
            {' '}
            like &quot;IP&quot; and &quot;Cookies&quot;. All we do process is a
            {' '}
            unique and completely anonymous &quot;clientId&quot; (localStorage)
            {' '}
            in your browser in order to distinguish between visitors.
          </p>
          <p>HYGA does NOT share or profit on any kind of data.</p>
          <h2>Keep in touch</h2>
          <p>email us@yatzo.nu</p>
          <p>{packageJson.version}</p>
        </Modal>
      );
    }
    return null;
  }

  render() {
    const {
      gameIndex,
      players,
      gameFull,
      gameStarted,
      gameOver,
      config,
    } = this.state;
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) refreshCacheAndReload();
          return (
            <div className='App'>
              <Scoresheet
                key={gameIndex}
                players={players}
                onGameStart={this.doGameStart}
                onGameOver={this.doGameOver}
                onSelectPlayer={(id, name) => this.setState({ selectedPlayer: { id, name } })}
                nextInLineId={gameIndex % players.length}
                config={config}
              />
              <div className='AppArea'>
                <div className='AppArea-header' />
                <MenuOption name='add' value='+' disabled={gameFull} onClick={this.doShowAddNewPlayer} />
                <MenuOption name={gameOver ? 'reset-gameOver' : 'reset'} value='R' disabled={(!gameStarted)} onClick={() => this.setState({ showResetConfirm: true })} />
                <MenuOption name='gameType' value={this.getOppositeConfig().type.toUpperCase()} onClick={() => this.setState({ showSwitchConfirm: true })} />
                <MenuOption name='info' value='i' onClick={() => this.setState({ showInformation: true })} />
                {this.renderPlayerEditor()}
                {this.renderGameReset()}
                {this.renderGameSwitch()}
                {this.renderInformation()}
              </div>
            </div>
          );
        }}
      </CacheBuster>
    );
  }
}

export default App;
