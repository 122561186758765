import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../Modal';
import ActionButton from '../../ActionButton';
import './NumPad.css';

class NumPad extends Component {
  constructor(props) {
    super(props);
    const { currentValue, currentThrows } = this.props;
    this.state = {
      value: currentValue || 0,
      throws: currentThrows || 3,
    };
  }

  doChange = (e) => {
    this.setState({ value: parseInt(e.target.value, 10) });
  }

  doSubmit = (e) => {
    const { value, throws } = this.state;
    const { onSubmit } = this.props;
    e.preventDefault();
    onSubmit(value, throws);
  }

  renderButtonClassNames = (n) => {
    const { value } = this.state;
    const classNames = ['Button'];
    if (n === value) classNames.push('Button-selected');
    return classNames.join(' ');
  }

  renderNumButtons = () => {
    const { numbers } = this.props;
    let op = 0.80;
    const opSteps = ((1 - op) / numbers.length);
    return numbers.map((n) => (
      <button
        className={this.renderButtonClassNames(n)}
        type='submit'
        key={n}
        value={n}
        style={{ opacity: op += opSteps }}
        onClick={this.doChange}
      >
        {n}
      </button>
    ));
  }

  renderThrowCounter = () => {
    const { throws } = this.state;
    const { currentThrows, savedThrows, throwCounter } = this.props;
    if (throwCounter) {
      return (
        <div className='ThrowCounter'>
          <ActionButton
            text='-'
            disabled={throws < 2}
            onClick={() => this.setState((prevState) => ({ throws: prevState.throws - 1 }))}
          />
          <p className='throws'>
            <b>{throws}</b>
            {(throws === 1) ? ' throw' : ' throws'}
          </p>
          <ActionButton
            text='+'
            disabled={throws >= ((currentThrows || 3) + savedThrows)}
            onClick={() => this.setState((prevState) => ({ throws: prevState.throws + 1 }))}
          />
        </div>
      );
    }
    return null;
  }

  render() {
    const { title, onClose, currentValue } = this.props;
    return (
      <Modal title={title} onClose={onClose}>
        <div className='NumPad'>
          {this.renderThrowCounter()}
          <p>Please, select your score:</p>
          <form onSubmit={this.doSubmit}>
            {this.renderNumButtons()}
            <div className='Actions'>
              <ActionButton
                type='red'
                text='Wipe'
                value={0}
                className={((currentValue === 0) ? 'Button-selected' : null)}
                onClick={this.doChange}
              />
              {(currentValue !== null) && (
                <ActionButton
                  type='green'
                  text='Reset'
                  value={-1}
                  onClick={this.doChange}
                />
              )}
            </div>
          </form>
        </div>
      </Modal>
    );
  }
}

NumPad.defaultProps = {
  currentValue: false,
  currentThrows: false,
  throwCounter: false,
};

NumPad.propTypes = {
  title: PropTypes.string.isRequired,
  numbers: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  currentValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  currentThrows: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  throwCounter: PropTypes.bool,
  savedThrows: PropTypes.number.isRequired,
};

export default NumPad;
