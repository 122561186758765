export default {
  type: 'yatzo',
  throwCount: false,
  upperSection: {
    bonusAt: (105 / 5) * 3,
    bonusStep: 3,
    bonusValue: 50,
    boxes: ['Ones', 'Twos', 'Threes', 'Fours', 'Fives', 'Sixes'].map((name, n) => ({
      name,
      min: (n + 1),
      max: (n + 1) * 5,
      step: (n + 1),
    })),
  },
  lowerSection: {
    boxes: [
      {
        name: 'One Pair',
        min: 2,
        max: 12,
        step: 2,
      },
      {
        name: 'Two Pair',
        min: 6,
        max: 22,
        step: 2,
      },
      {
        name: 'Three of a Kind',
        min: 3,
        max: 18,
        step: 3,
      },
      {
        name: 'Four of a Kind',
        min: 4,
        max: 24,
        step: 4,
      },
      {
        name: 'Small Straight',
        min: 15,
        max: 15,
        step: 1,
      },
      {
        name: 'Large Straight',
        min: 20,
        max: 20,
        step: 1,
      },
      {
        name: 'Full House',
        min: 7,
        max: 28,
        step: 1,
      },
      {
        name: 'Chance',
        min: 5,
        max: 30,
        step: 1,
      },
      {
        name: 'Yatzo',
        min: 50,
        max: 50,
        step: 1,
      },
    ],
  },
};
