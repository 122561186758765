import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Section from './Section';
import SumBox from './SumBox';
import './Player.css';

class Player extends Component {
  constructor(props) {
    super(props);
    const { config } = this.props;
    this.upperSectionMax = config.upperSection.boxes.map((x) => x.max).reduce((t, n) => t + n);
    this.lowerSectionMax = config.lowerSection.boxes.map((x) => x.max).reduce((t, n) => t + n);
    this.bonusMax = config.upperSection.bonusValue;
    this.state = {
      upperSectionTotal: 0,
      lowerSectionTotal: 0,
      bonusTotal: 0,
      upperSectionTotalLeft: this.upperSectionMax,
      upperSectionBoxes: 0,
      lowerSectionTotalLeft: this.lowerSectionMax,
      lowerSectionBoxes: 0,
      bonusTotalLeft: this.bonusMax,
      bonusBalance: 0,
      upperSectionThrows: 0,
      lowerSectionThrows: 0,
    };
  }

  doUpdateUpperSection = (total, totalLeft, bonusBalance, upperSectionBoxes, throws) => {
    this.setState({
      upperSectionTotal: total,
      upperSectionTotalLeft: totalLeft,
      bonusBalance,
      upperSectionBoxes,
      upperSectionThrows: throws,
    }, () => {
      const { bonusTotal } = this.state;
      const giveBonus = (this.bonusAchieved() && !bonusTotal);
      const revokeBonus = (!this.bonusAchieved() && bonusTotal);
      if (giveBonus) {
        this.setState({ bonusTotal: this.bonusMax, bonusTotalLeft: 0 }, this.onUpdate);
      } else if (revokeBonus) {
        this.setState({
          bonusTotal: 0,
          bonusTotalLeft: this.unableToAchiveBonus() ? 0 : this.bonusMax,
        }, this.onUpdate);
      } else if (this.unableToAchiveBonus()) {
        this.setState({ bonusTotalLeft: 0 }, this.onUpdate);
      } else {
        this.setState({
          bonusTotalLeft: this.bonusAchieved() ? 0 : this.bonusMax,
        }, this.onUpdate);
      }
    });
  }

  doUpdateLowerSection = (total, totalLeft, _, lowerSectionBoxes, throws) => {
    this.setState({
      lowerSectionTotal: total,
      lowerSectionTotalLeft: totalLeft,
      lowerSectionBoxes,
      lowerSectionThrows: throws,
    }, this.onUpdate);
  }

  doSelectPlayer = () => {
    const { id, name, onSelectPlayer } = this.props;
    onSelectPlayer(id, name);
  }

  onUpdate = () => {
    const { id, name, onUpdate } = this.props;
    onUpdate(id, name, this.getGrandTotal(), this.getGrandTotalLeft(), this.getTotalBoxes());
  }

  bonusAchieved = () => {
    const { upperSectionTotal } = this.state;
    const { config } = this.props;
    return upperSectionTotal >= config.upperSection.bonusAt;
  }

  unableToAchiveBonus = () => {
    const { upperSectionTotal, upperSectionTotalLeft } = this.state;
    const { config } = this.props;
    return (upperSectionTotal + upperSectionTotalLeft) < config.upperSection.bonusAt;
  }

  unableToWinTheGame = () => {
    const { id, leaderId, leaderGrandTotal } = this.props;
    if ((leaderId !== false) && leaderGrandTotal) {
      if (id !== leaderId) {
        if (this.getGrandTotal() + this.getGrandTotalLeft() < leaderGrandTotal) {
          return true;
        }
      }
    }
    return false;
  }

  getGrandTotal = () => {
    const { upperSectionTotal, lowerSectionTotal, bonusTotal } = this.state;
    return (upperSectionTotal + lowerSectionTotal + bonusTotal);
  }

  getGrandTotalLeft = () => {
    const { upperSectionTotalLeft, lowerSectionTotalLeft, bonusTotalLeft } = this.state;
    return (upperSectionTotalLeft + lowerSectionTotalLeft + bonusTotalLeft);
  }

  getTotalBoxes = () => {
    const { upperSectionBoxes, lowerSectionBoxes } = this.state;
    return upperSectionBoxes + lowerSectionBoxes;
  }

  getSavedThrows = () => {
    const { upperSectionThrows, lowerSectionThrows } = this.state;
    return (this.getTotalBoxes() * 3) - (upperSectionThrows + lowerSectionThrows);
  }

  isActive = () => {
    const { id, nextInLineId } = this.props;
    return this.getGrandTotalLeft() && (id === nextInLineId);
  }

  isInTheLead = () => {
    const { id, leaderId } = this.props;
    return ((leaderId !== false) && (id === leaderId));
  }

  renderClassNames = () => {
    const { gameOver } = this.props;
    const classNames = ['Player'];
    if (this.isInTheLead()) classNames.push('Player-leader');
    if (this.isInTheLead() && gameOver) classNames.push('Player-winner');
    if (this.isActive()) classNames.push('Player-active');
    else classNames.push('Player-inactive');
    if (!this.getGrandTotalLeft()) classNames.push('Player-done');
    return classNames.join(' ');
  }

  render() {
    const { name, config } = this.props;
    const { bonusTotal, bonusBalance } = this.state;
    const totalBoxes = config.upperSection.boxes.length + config.lowerSection.boxes.length + 5;
    const boxHeight = `${100 / totalBoxes}%`;
    const grandTotal = this.getGrandTotal();
    const grandTotalLeft = this.getGrandTotalLeft();
    const savedThrows = this.getSavedThrows();
    return (
      <div className={this.renderClassNames()}>
        {(savedThrows !== 0) && <div className={['Player-throws', `positive-${Boolean(savedThrows > 0)}`].join(' ')}>{savedThrows}</div>}
        <div className='Player-name' onClick={this.doSelectPlayer}>
          <p>{name}</p>
        </div>
        <div className='Scoresheet-main'>
          <Section
            name={name}
            max={this.upperSectionMax}
            boxes={config.upperSection.boxes}
            onChange={this.doUpdateUpperSection}
            boxHeight={boxHeight}
            bonusStep={config.upperSection.bonusStep}
            enableThrowCount={config.throwCount}
            savedThrows={savedThrows}
          />
          <SumBox
            value={bonusTotal}
            bonusBalance={bonusBalance}
            disabled={this.unableToAchiveBonus()}
            style={{ height: boxHeight }}
          />
          <Section
            name={name}
            max={this.lowerSectionMax}
            boxes={config.lowerSection.boxes}
            onChange={this.doUpdateLowerSection}
            boxHeight={boxHeight}
            savedThrows={savedThrows}
            enableThrowCount={config.throwCount}
          />
          <SumBox
            value={grandTotal}
            className='GrandTotal'
            style={{
              height: boxHeight,
              backgroundColor: 'rgba(0, 0, 0, .1)',
              borderBottom: '1px solid rgba(0, 0, 0, .3)',
            }}
          />
          <SumBox
            value={grandTotal + grandTotalLeft}
            warning={this.unableToWinTheGame()}
            disabled={!grandTotalLeft}
            style={{ height: boxHeight, borderBottom: 0, background: 'none' }}
          />
        </div>
      </div>
    );
  }
}

const boxes = PropTypes.shape({
  name: PropTypes.string.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
});

Player.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  leaderId: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
  leaderGrandTotal: PropTypes.number.isRequired,
  nextInLineId: PropTypes.number.isRequired,
  onUpdate: PropTypes.func.isRequired,
  gameOver: PropTypes.bool.isRequired,
  onSelectPlayer: PropTypes.func.isRequired,
  config: PropTypes.shape({
    throwCount: PropTypes.bool.isRequired,
    upperSection: PropTypes.shape({
      bonusAt: PropTypes.number.isRequired,
      bonusStep: PropTypes.number.isRequired,
      bonusValue: PropTypes.number.isRequired,
      boxes: PropTypes.arrayOf(boxes),
    }).isRequired,
    lowerSection: PropTypes.shape({
      boxes: PropTypes.arrayOf(boxes),
    }).isRequired,
  }).isRequired,
};

export default Player;
