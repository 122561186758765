import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './SumBox.css';

class SumBox extends Component {
  formatBonusBalance = (balance) => (balance > 0 ? `+${balance}` : balance);

  renderBonusBalance = (balance) => {
    const { value, disabled } = this.props;
    if (balance && !value && !disabled) {
      const classNames = ['SumBox-balance'];
      if (balance > 0) classNames.push('SumBox-balance--positive');
      else classNames.push('SumBox-balance--negative');
      return <div className={classNames.join(' ')}>{this.formatBonusBalance(balance)}</div>;
    }
    return null;
  }

  renderClassNames = () => {
    const { warning, disabled, className } = this.props;
    const classNames = ['SumBox'];
    if (warning && !disabled) classNames.push('SumBox-warning');
    if (className) classNames.push(className);
    return classNames.join(' ');
  }

  render() {
    const {
      value,
      disabled,
      bonusBalance,
      style,
    } = this.props;
    return (
      <div className={this.renderClassNames()} style={style}>
        {disabled ? '-' : value || null}
        {this.renderBonusBalance(bonusBalance)}
      </div>
    );
  }
}

SumBox.defaultProps = {
  disabled: false,
  warning: false,
  bonusBalance: 0,
  className: false,
};

SumBox.propTypes = {
  value: PropTypes.number.isRequired,
  bonusBalance: PropTypes.number,
  disabled: PropTypes.bool,
  warning: PropTypes.bool,
  className: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default SumBox;
