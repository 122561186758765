import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './MenuOption.css';

class MenuOption extends Component {
  renderClassNames = () => {
    const { name, disabled } = this.props;
    const classNames = ['MenuOption'];
    if (disabled) classNames.push('MenuOption-disabled');
    classNames.push(name);
    return classNames.join(' ');
  }

  render() {
    const { value, onClick } = this.props;
    return (
      <div className={this.renderClassNames()} onClick={onClick}>
        {value}
      </div>
    );
  }
}

MenuOption.defaultProps = {
  disabled: false,
};

MenuOption.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default MenuOption;
