import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NumPad from './NumPad';
import './ScoreBox.css';

class ScoreBoxValue extends Component {
  renderClassNames = () => {
    const { value } = this.props;
    const classNames = ['ScoreBoxValue'];
    if (value > 0) classNames.push('ScoreBoxValue-filled');
    else if (value === 0) classNames.push('ScoreBoxValue-wiped');
    return classNames.join(' ');
  }

  render() {
    const { onClick, value } = this.props;
    return (
      <div className={this.renderClassNames()} onClick={onClick}>
        {value === 0 ? null : value}
      </div>
    );
  }
}

ScoreBoxValue.defaultProps = { value: null };

ScoreBoxValue.propTypes = {
  value: PropTypes.number,
  onClick: PropTypes.func.isRequired,
};

class ScoreBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      value: null,
      throws: 0,
      bonusBalance: 0,
    };
  }

  doUpdate = (updateValue, updateThrows) => {
    const { value, throws, bonusBalance } = this.state;
    const {
      max,
      step,
      bonusStep,
      onUpdate,
    } = this.props;
    const initValue = value || 0;
    const initThrows = throws;
    const initEmpty = value === null;
    const initBonusBalance = bonusBalance;
    let newValue = updateValue;
    let newThrows = updateThrows;
    let newBonusBalance = newValue - (parseInt(step, 0) * bonusStep);
    let wastedValue = value === null ? max : 0;
    if (updateValue < 0) {
      newValue = null;
      newThrows = 0;
      newBonusBalance = 0;
      wastedValue = -max;
    }
    this.setState({
      value: newValue,
      throws: newThrows,
      bonusBalance: newBonusBalance,
    }, onUpdate(
      newValue,
      initValue,
      wastedValue,
      newThrows,
      initThrows,
      newBonusBalance,
      initBonusBalance,
      (updateValue < 0) ? -1 : (initEmpty ? 1 : 0),
    ));
  }

  renderNumPad = () => {
    const { isEditing, value, throws } = this.state;
    const {
      title,
      min,
      max,
      step,
      savedThrows,
      enableThrowCount,
    } = this.props;
    if (isEditing) {
      return (
        <NumPad
          title={title}
          numbers={Array(
            Math.floor((max - min) / step) + 1,
          ).fill().map((_, idx) => min + (idx * step))}
          currentValue={value}
          currentThrows={throws}
          onSubmit={this.doUpdate}
          onClose={() => this.setState({ isEditing: false })}
          savedThrows={savedThrows}
          throwCounter={enableThrowCount}
        />
      );
    }
    return null;
  }

  renderClassNames = () => {
    const { value } = this.state;
    const classNames = ['ScoreBox'];
    if (value === null) classNames.push('ScoreBox-disabled');
    return classNames.join(' ');
  }

  render() {
    const { value } = this.state;
    const { style } = this.props;
    return (
      <div className={this.renderClassNames()} style={style}>
        <ScoreBoxValue value={value} onClick={() => this.setState({ isEditing: true })} />
        {this.renderNumPad()}
      </div>
    );
  }
}

ScoreBox.propTypes = {
  title: PropTypes.string.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  bonusStep: PropTypes.number.isRequired,
  savedThrows: PropTypes.number.isRequired,
  enableThrowCount: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default ScoreBox;
