import { Component } from 'react';
import packageJson from '../../package.json';

const versionGreaterThan = (x, y) => {
  const versionsA = x.split(/\./g);
  const versionsB = y.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());
    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

class CacheBuster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isLatestVersion: false,
      refreshCacheAndReload: () => {
        if ('caches' in window) {
          caches.keys().then(async (names) => {
            await Promise.all(names.map((name) => caches.delete(name)));
          });
        }
        window.location.reload(true);
      },
    };
  }

  componentDidMount() {
    fetch(`/build-version.json?${new Date().getTime()}`, { cache: 'no-cache' })
      .then((response) => response.json())
      .then((data) => {
        if (
          versionGreaterThan(data.version, packageJson.version)
        ) this.setState({ loading: false, isLatestVersion: false });
        else this.setState({ loading: false, isLatestVersion: true });
      });
  }

  render() {
    const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
    const { children } = this.props;
    return children({ loading, isLatestVersion, refreshCacheAndReload });
  }
}

export default CacheBuster;
